<template>
  <div>
    <Content :style="{ padding: '0 24px 16px' }">
      <Modal
        v-model="addDevModal"
        title="请选择设备"
        @on-ok="admok"
        @on-cancel="admcancel"
        :width="700"
        class="addDevModal"
      >
        <div class="adm">
          <div class="adm-search">
            <Form
              :model="admForm"
              :label-width="60"
              label-position="left"
              @keydown.native.enter.prevent="() => {}"
            >
              <FormItem label="搜索">
                <Input
                  v-model="admForm.searchText"
                  placeholder="请输入设备机台号"
                  @on-enter="getFather"
                ></Input>
              </FormItem>
            </Form>
          </div>
          <div class="adm-table">
            <Table
              :height="300"
              :highlight-row="true"
              no-data-text=" "
              :columns="admColumns"
              :data="admData"
              @on-row-click="getRowInfo"
            ></Table>
          </div>
        </div>
      </Modal>
      <div class="main-body">
        <Form
          class="formValidate"
          ref="formValidate"
          :model="formValidate"
          :rules="ruleValidate"
          :label-width="110"
        >
          <div class="header_container">
            <div class="infoTitle_">设备保养单</div>
            <div class="action-area">
              <div class="actBtn save" @click="handleAdd">
                <i class="icon1 iconzengjia1-copy"></i>
                <span>新增保养设备</span>
              </div>
              <div class="actBtn save" @click="save('formValidate')">
                <i class="icon1 iconbaocun"></i>
                <span>保存</span>
              </div>
              <div class="actBtn cancle" @click="cancle">
                <i class="icon1 iconiconquxiao-06"></i>
                <span>取消</span>
              </div>
            </div>
          </div>
          <div class="basicInfo">
            <div class="infoDetail">
              <FormItem>
                <Row style="border-bottom:1px solid #DFE3E8;">
                  <Col span="10">
                    <FormItem prop="byCode" label="设备保养单号">
                      <Input
                        v-model="formValidate.byCode"
                        placeholder="请输入"
                        style="width:70%;border:none;"
                        disabled
                      ></Input>
                    </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem prop="creatername" label="制单人">
                      <Input
                        v-model="formValidate.creatername"
                        placeholder="请输入"
                        style="width:70%;border:none;"
                        disabled
                      ></Input>
                    </FormItem>
                  </Col>
                </Row>
                <Row style="border-bottom:1px solid #DFE3E8;">
                  <Col span="10">
                    <FormItem prop="plantime" label="计划保养日期">
                      <DatePicker
                        type="date"
                        placeholder="请选择日期"
                        v-model="formValidate.plantime"
                        style="width:70%;border:none;"
                      ></DatePicker>
                    </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem prop="creattime" label="制单日期">
                      <DatePicker
                        type="date"
                        placeholder="请选择日期"
                        v-model="formValidate.creattime"
                        style="width:70%;border:none;"
                        disabled
                      ></DatePicker>
                    </FormItem>
                  </Col>
                </Row>
                <!-- v-if="item.status" -->
                <Row
                  v-for="(item, index) in formValidate.items"
                  :key="index"
                  style="border-bottom:1px solid #DFE3E8;"
                >
                  <Col span="10">
                    <FormItem
                      :prop="'items.' + index + '.deviceId'"
                      label="机台号"
                    >
                      <Row>
                        <Col :span="8">
                          <Input
                            type="text"
                            v-model="item.deviceNum"
                            @on-focus="
                              addDevModal = true;
                              currentIndex = index;
                            "
                          ></Input>
                        </Col>
                        <Col :span="8">
                          <div
                            v-show="item.deviceNum != ''"
                            style="font-size: 16px;font-family: Source Han Sans CN;font-weight: 500;color: #333333;"
                          >
                            设备编号:{{ item.serialNumber }}
                          </div>
                        </Col>
                      </Row>
                    </FormItem>
                  </Col>
                  <Col span="10">
                    <!-- :rules="[{required: true, message: '设备编号不能为空', trigger: 'blur'},{type:'string',max:200,message:'长度最大为200',trigger: 'blur'}]" -->
                    <FormItem
                      :prop="'items.' + index + '.byContent'"
                      label="保养内容"
                    >
                      <Input
                        type="text"
                        v-model="item.byContent"
                        placeholder="请输入保养内容"
                        style="width:70%;border:none;"
                        maxlength="200"
                      ></Input>
                      <i
                        class="icon1 iconfuzhi-copy"
                        style="cursor: pointer;"
                        v-clipboard:copy="item.byContent"
                        v-clipboard:success="onCopyUrl"
                        v-clipboard:error="onErrorUrl"
                      ></i>
                      <!-- <img src="/static/images/aoya/copy.png" style="cursor: pointer;" v-clipboard:copy="item.byContent" v-clipboard:success="onCopyUrl" v-clipboard:error="onErrorUrl"></img> -->
                    </FormItem>
                  </Col>
                  <Col span="1" v-if="index != 0">
                    <FormItem>
                      <Button type="dashed" @click="handleRemove(index)"
                        >删除</Button
                      >
                    </FormItem>
                  </Col>
                </Row>
              </FormItem>
              <!-- <FormItem
                            v-for="(item, index) in formValidate.items"
                            v-if="item.status"
                            :key="index"
                            label="设备编号"
                            :prop="'items.' + index + '.value'">
                        <Row>
                            <Col span="10">
                                <Input type="text" v-model="item.value" placeholder="请输入设备编号" style="width:200px;border:none;"></Input>
                            </Col>
                            <Col span="10">
                                <Input type="text" v-model="item.value" placeholder="请输入设备编号" style="width:200px;border:none;"></Input>
                            </Col>
                            <Col span="2" offset="1">
                                <Button type="text" @click="handleRemove(index)">x</Button>
                            </Col>
                        </Row>
                    </FormItem> -->
            </div>
          </div>
        </Form>
      </div>
    </Content>
  </div>
</template>

<script>
export default {
  data() {
    return {
      addDevModal: false, // 显示选择设备编号弹窗
      admData: [],
      admForm: {
        searchText: "" // 搜索关键词
      },
      tempDevId: "",
      tempDevNum: "",
      tempSerialNumber: "",
      admColumns: [
        {
          title: "机台号",
          key: "deviceNum",
          width: "150"
        },
        {
          title: "设备编号",
          key: "serialNumber",
          width: "170"
        },
        {
          title: "设备类型",
          key: "deviceType",
          width: "180"
        },
        {
          title: "所属车间",
          key: "workshop",
          width: "150"
        }
      ],
      currentIndex: 0,
      parentGrouplist: [], // 设备父组树状表
      parentGroupId: "",
      defaultProps: {
        children: "list",
        label: "deviceNum"
      },
      show: false,
      index: 1,
      formValidate: {
        devId: "",
        devType: "",
        workshop: "",
        date: "",
        items: [
          {
            index: 1,
            status: false,
            deviceId: "",
            deviceNum: "",
            serialNumber: "", //
            byContent: "",
            name: "", // 搜索框内容
            parentGrouplist: [] // 当前搜索下的tree
          }
        ],

        byCode: "", // "BY20190920001",            保养单号
        creattime: "", // "2019-09-20",            创建时间
        createrId: "", // 1,                        制单人id
        creatername: "", // "admin"                制单人姓名
        plantime: ""
      },
      ruleValidate: {}
    };
  },
  methods: {
    onCopyUrl: function(e) {
      this.$message.success("复制成功！");
    },
    onErrorUrl: function(e) {
      this.$message.success("复制失败！");
    },
    getCurrentIndex(num) {
      this.currentIndex = num;
    },
    handleNodeClick(data) {
      if (data.deviceId !== undefined) {
        this.formValidate.items[this.currentIndex].deviceId = data.deviceId;
        this.formValidate.items[this.currentIndex].status = false;
        this.formValidate.items[this.currentIndex].deviceNum = data.deviceNum;
      }
    },
    formateDate(datetime) {
      function addDateZero(num) {
        return num < 10 ? "0" + num : num;
      }
      const d = new Date(datetime);
      const formatdatetime =
        d.getFullYear() +
        "-" +
        addDateZero(d.getMonth() + 1) +
        "-" +
        addDateZero(d.getDate());
      return formatdatetime;
    },
    getBasicInfo() {
      this.axios({
        url: "/iext/back/device/DeviceController/addMaintain",
        method: "get"
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.formValidate.byCode = res.data.body.byCode;
            this.formValidate.creattime = res.data.body.creattime; // 创建时间
            this.formValidate.creatername = res.data.body.creatername; // 制单人姓名
            this.formValidate.createrId = res.data.body.createrId; // 制单人id
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getFather() {
      this.axios({
        url: "/iext/back/device/DeviceController/maintainDevice",
        method: "get",
        params: {
          deviceNum: this.admForm.searchText
        }
      })
        .then((res) => {
          this.admData = res.data.body.numlist;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cancle() {
      this.$router.go(-1); // 返回上一层
    },
    save(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          for (let i = 0; i < this.formValidate.items.length; i++) {
            if (
              this.formValidate.items[i].deviceId === "" ||
              this.formValidate.items[i].byContent === "" ||
              this.formValidate.plantime === ""
            ) {
              this.$message.error("请将信息补充完成后再提交！");
              return false;
            }
          }
          var params = {};
          params.byCode = this.formValidate.byCode;
          params.plantime = this.formateDate(this.formValidate.plantime);
          params.creattime = this.formateDate(this.formValidate.creattime);
          params.createrId = this.formValidate.createrId;
          var arr = [];
          for (let i = 0; i < this.formValidate.items.length; i++) {
            var param = {};
            param.deviceId = this.formValidate.items[i].deviceId;
            param.byContent = this.formValidate.items[i].byContent;
            arr.push(param);
          }
          params.list = arr;
          this.axios({
            url: "/iext/back/device/DeviceController/saveMaintain",
            method: "post",
            data: params
          })
            .then((res) => {
              if (res.data.success === 1) {
                this.$router.push({
                  path: "/deviceManage/devmaintenance"
                });
              } else {
                this.$Message.error(res.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    handleAdd() {
      const that = this;
      that.index++;
      that.formValidate.items.push({
        // value: '',
        index: that.index,
        status: false,
        deviceId: "",
        byContent: "",
        name: "",
        parentGrouplist: [], // 当前搜索下的tree
        deviceNum: "",
        serialNumber: ""
      });
    },
    handleRemove(index) {
      this.formValidate.items.splice(index, 1);
      // this.formValidate.items[index].status = 0;
    },
    // 弹窗点击一行获取数据
    getRowInfo(e) {
      this.tempDevId = e.deviceId;
      this.tempDevNum = e.deviceNum;
      this.tempSerialNumber = e.serialNumber;
    },
    admok() {
      this.formValidate.items[this.currentIndex].deviceNum = this.tempDevNum;
      this.formValidate.items[this.currentIndex].deviceId = this.tempDevId;
      this.formValidate.items[
        this.currentIndex
      ].serialNumber = this.tempSerialNumber;
      // debugger
      this.addDevModal = false;
    },
    admcancel() {}
  },
  created() {},
  mounted() {
    this.getBasicInfo();
    this.getFather();
    this.$Message.config({
      duration: 5
    });
  }
};
</script>

<style lang="scss" scoped>
.main-body {
  width: 100%;
  height: 100%;
  // background: rgba(228,235,241,1);
  padding-bottom: 0;
  .formValidate {
    .header_container {
      display: flex;
      justify-content: space-between;
      .infoTitle_ {
        // font-size:16px;
        // font-family:PingFangSC;
        // font-weight:500;
        // color:rgba(51,51,51,1);
        height: 70px;
        line-height: 70px;
        text-align: center;
        width: 120px;

        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #222222;
      }
      .action-area {
        width: 300px;
        height: 70px;
        line-height: 70px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .actBtn {
          margin: 30px 10px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: rgba(99, 115, 129, 1);
          cursor: pointer;
        }
        .actBtn :hover {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #2980b9;
          cursor: pointer;
        }
      }
    }
    .basicInfo {
      .infoDetail {
        .ivu-form-item {
          // margin:10px 0;
        }
        .ivu-form-item /deep/ .ivu-form-item-content {
          margin-left: 0px !important;
          height: 56px;
          line-height: 56px;
          input {
            border: none;
          }
          .ivu-select-selection {
            border: none;
          }
          .ivu-input {
            background: none;
          }
        }
        .ivu-form-item /deep/ .ivu-form-item-label {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: rgba(51, 51, 51, 1);
          // border-bottom:1px solid #DFE3E8;
          padding: 0;
          line-height: 56px;
          height: 56px;
          background: rgba(247, 250, 255, 1);
          text-align: center;
          border-bottom: 1px solid #dfe3e8;
        }
      }
      // .infoDetail >>> .ivu-form-item >>>.ivu-form-item-content{
      //             margin-left:0px !important;
      //         }
    }
  }
  .otherInfo {
    .infoTitle {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: rgba(51, 51, 51, 1);
    }
    .infoDetail {
      .otherForm {
        .ivu-form-item {
          // margin:10px 0;
          // margin-bottom:0px !important;
        }
        .ivu-form-item /deep/ .ivu-form-item-content {
          margin-left: 0px !important;
          .textfont {
            height: 34px;
            line-height: 34px;
          }
          .ivu-input {
            background: none;
          }
        }
        .ivu-form-item /deep/ .ivu-form-item-label {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: rgba(51, 51, 51, 1);
          border-bottom: 1px solid #dfe3e8;
          background: rgba(247, 250, 255, 1);
        }
      }
    }
  }
}
.main-body
  .formValidate
  .basicInfo
  .infoDetail
  .ivu-form-item
  >>> .ivu-form-item-label {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #333;
  border-bottom: 1px solid #dfe3e8;
  text-align: center;
  margin-left: 0px !important;
}
.main-body
  .formValidate
  .basicInfo
  .infoDetail
  .ivu-form-item
  >>> .ivu-form-item-content {
  input {
    border: none;
  }
}

.addDevModal /deep/ {
  .ivu-modal-wrap {
    .ivu-modal {
      .ivu-modal-content {
        .ivu-modal-body {
          padding: 14px 16px;
          .adm {
            .adm-table {
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
}
</style>
